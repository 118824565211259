import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { FaChevronRight } from "react-icons/fa";

const ItemsContainer = ({ background, name, testimony }) => {
  return (
    <ItemCont background={background} style={{ marginBottom: "50px" }}>
      <TitleContainer>
        <h3>Testimonials</h3>
        <Underline />
      </TitleContainer>
      <CarouselText>
        <TestimontTitle>{name}</TestimontTitle>
        <Testify> {testimony}</Testify>
        <ViewAllLink>
          VIEW All <StylecChevromRight size={35} />{" "}
        </ViewAllLink>
      </CarouselText>
    </ItemCont>
  );
};

const ItemCont = styled.div`
  background-image: url(${(props) => props.background});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  @media (max-width: 970px) {
    height: 500px;
  }
`;
const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  text-align: center;
  font-family: 'Archivo';
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
  justify-content: center;
  height: 40px;
  border: 0px;
  & h3 {
    color: #fff;
    font-weight: 800;
    font-size: 35px;
    z-index: 10000;
    padding-top: 5rem;
    @media (max-width: 970px) {
      margin-top: 10px;
      margin-bottom: 0px;
      font-weight: 600;
      font-size: 15px;
    }
  }
  @media (max-width: 970px) {
    margin-top: -44px;
    margin-bottom: 0px;
  }
`;
const TestimontTitle = styled.p`
  color: #fff;
  order: -1;
  text-transform: uppercase;
  font-size: 35px;
  font-family: 'Archivo';
  letter-spacing: 0.7px;
  font-weight: 700;
  z-index: 1000;
  top: 50px;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  margin-top: 20px;
  @media (max-width: 970px) {
    margin-top: 100px;

    font-size: 18px;
    margin-top: 47px;
    top: 0px;
  }
`;

const Testify = styled.p`
  color: #fff;
  font-family: 'Archivo';
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 3rem;
  text-align: center;
  max-width: 864px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  @media (max-width: 970px) {
    font-size: 15px;
    padding-left: 11px;
    padding-right: 11px;
    margin-top: 0px;
    line-height: 1.4rem;
  }
`;

const CarouselText = styled.p`
  height: 90%;
  color: #fff;
  top: 105px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  font-family: 'Archivo';
  margin-bottom: 30px;
  min-height: 250px;
  @media (max-width: 950px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    top: 50px;
    height: 100%;
  }
`;

const ViewAllLink = styled(Link)`
  box-shadow: none;
  padding-top: 7px;
  opacity: 1;
  border-radius: 6px;
  width: 400px;
  height: 70px;
  bottom: 15px;
  color: #fff;
  font-family: 'Archivo';
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1.6px;
  will-change: background, opacity;
  background: #0066b2;
  margin-top: auto;
  margin-bottom: 27px;
  opacity: 0.7;
  align-self: center;
  transition: opacity ease-in 0.1s;
  @media (max-width: 970px) {
    margin-top: 9 0px;
    width: 217px;
    padding-bottom: 50px;
    margin-bottom: 50px;
  }
  &:hover {
    color: #fff;
    opacity: 1;
  }
`;

const Underline = styled.p`
  width: 60px;
  height: 2px;
  margin-top: 130px;
  background: #0066b2;
  position: absolute;
  @media (max-width: 970px) {
    margin-top: 0px;
  }
`;

const StylecChevromRight = styled(FaChevronRight)`
  padding-top: 20px;
`;
export default ItemsContainer;
