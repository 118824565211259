import React from "react";
import styled from "styled-components";

import image from "../../img/scrumanacLocation.png";

// const mapUrl =
//   "https://maps.googleapis.com/maps/api/staticmap?center=ventures+park+29+Mambilla+St,+Maitama+900271,+Abuja&zoom=15&scale=false&size=640x569&maptype=roadmap&key=AIzaSyB-GxhmqzzwowROhIQdlJAVo3EDPqdH6X8&format=png&visual_refresh=true&markers=icon:https://landing.jobs/at/2831%7Cshadow:true%7Cventures+park+29+Mambilla+St,+Maitama+900271,+Abuja";
const MapCard = () => {
  const DataSrc =
    "https://www.google.com/maps/@9.0800531,7.5113164,442m/data=!3m1!1e3";

  const data = [
    {
      title: "Abuja - Nigeria",
      street: "5 Kwaji Close Wuse 2, Maitama 904101, Abuja Nigeria",
      phone: "+234-8108900672",
      imageUrl: image,
      DataSrc,
    },
  ];

  return (
    <Container>
      <CardContainer>
        {data.map((item) => (
          <CardItem>
            <CardItemTop>
              <CardItemTitle>{item.title}</CardItemTitle>
              <CardSubTitle>Nigeria Headquarters</CardSubTitle>
              <Street>{item.street}</Street>
              <Phone href={"tel:" + item.phone}> Tel: {item.phone}</Phone>
            </CardItemTop>
            <iframe
              title="Nigeria Headquarters"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3939.7033349760504!2d7.472843614787084!3d9.090766693478026!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e0a3abd650625%3A0x19efbfa4aaa4eb2a!2sVentures%20Park!5e0!3m2!1sen!2sng!4v1634052985893!5m2!1sen!2sng"
              width="100%"
              height="550"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          </CardItem>
        ))}
      </CardContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 20px;
  @media (max-width: 970px) {
    align-self: center;
  }
`;
const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  margin-bottom: 20px;

  @media (max-width: 950px) {
    text-align: left;
  }
`;
const CardItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background: #272727;
  margin-bottom: 35px;
  @media (max-width: 520px) {
    width: 300px;
  }
`;

const CardItemTop = styled.div`
  padding: 45px 39px 39px;
  font-family: 'Archivo';
  @media (max-width: 970px) {
    padding: 20px 20px 20px;
    justify-content: center;
  }
`;
const CardItemTitle = styled.p`
  letter-spacing: 0.2px;
  font-family: 'Archivo';
  font-weight: 600;
  width: 100%;
  font-size: 24px;
  text-align: left;
  line-height: 1;
  margin-bottom: 1rem;
  transition: color ease-in 0.8s;
`;

const CardSubTitle = styled.p`
  font-family: 'Archivo' Heveltica;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1;
  width: 100%;
  margin-bottom: 12px;

  transition: color ease-in 0.8s;
`;

const Street = styled.p`
  font-size: 0.875rem;
  line-height: 1.6;
  font-weight: 400;

  height: 34px;
  width: 40%;
  height: 33.6634px;
  transition: color ease-in 0.8s;
  font-family: 'Archivo';
  @media (max-width: 970px) {
    margin-bottom: 45px;
  }
  @media (max-width: 500px) {
    margin-bottom: 85px;
  }
`;

const Phone = styled.p`
  margin-right: 2rem;
  font-family: 'Archivo';
  font-weight: 600;
  color: #fff;
  font-size: 1rem;
  line-height: 1.375rem;
  letter-spacing: -0.1px;

  @media (max-width: 970px) {
    margin-top: 60px;
  }
  @media (max-width: 520px) {
    padding-top: 100px;
    // margin-top: 139px;
  }

  &:hover {
    opacity: 1;
  }
`;

// const MapImg = styled.img.attrs((props) => ({
//   src: props.Img || " ",
// }))`
//   width: 100%;
// `;

// const MapImage = styled.div`
//   background: url(${mapUrl});
//   width: 100%;
//   height: 874px;
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-position: center;
//   @media (max-width: 970px) {
//     height: 300px;
//   }
// `;

// const MapButton = styled.button`
//   display: block;
//   width: 30%;
//   height: 4rem;
//   box-shadow: none;
//   border: 0px;
//   background: transparent;
//   font-weight: 700;
//   font-size: 20px;
//   cursor: pointer;
//   color: #fff;
//   margin-left: auto;
//   margin-right: 0px;
//   margin-top: 380px;
//   font-family: 'Archivo';
//   font-weight: 600;
//   text-transform: uppercase;
//   letter-spacing: 1.6px;
//   @media (max-width: 970px) {
//     margin-top: 245px;
//     width: 50%;
//     margin-left: 0px;
//     margin-right: auto;
//     font-size: 17px;
//   }
// `;

export default MapCard;
