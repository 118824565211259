import React from "react";
import styled from "styled-components";
import ServiceCard from "./ServiceCard";
import { StaticQuery, graphql } from "gatsby";

const IndexServiceSection = () => {
  return (
    <StaticQuery
      query={graphql`
        query ServicesSectionQuery {
          markdownRemark(frontmatter: { title: { eq: "ServiceSection" } }) {
            id
            frontmatter {
              title
              templateKey
              poster {
                title
                flipTitle
                introduction
                icon {
                  publicURL
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <ServiceSection>
          <ContentContainer>
            <SectionTitle>Services</SectionTitle>
            <Container>
              {data.markdownRemark.frontmatter.poster &&
                data.markdownRemark.frontmatter.poster.map &&
                data.markdownRemark.frontmatter.poster.map((item) => (
                  <ServiceCard
                    icon={item.icon.publicURL}
                    title={item.title}
                    introduction={item.introduction}
                    FlipTitle={item.flipTitle}
                  />
                ))}
            </Container>
          </ContentContainer>
        </ServiceSection>
      )}
    />
  );
};

const ServiceSection = styled.div`
  background: #f5f5f5;

  padding-top: 5rem;
  padding-bottom: 5rem;
  position: relative;
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  font-family: 'Archivo';
  margin-left: auto;
  margin-right: auto;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  font-weight: 300;
  flex-wrap: wrap;
  margin-right: auto;
  margin-left: auto;
  @media (max-width: 819px) {
    flex-direction: column;
  }
`;

const SectionTitle = styled.p`
  font-family: 'Archivo';
  font-weight: 900;
  letter-spacing: 0.7px;
  line-height: 48px;
  text-align: center;
  text-transform: uppercase;
  font-size: 32px;
  position: relative;
  margin-bottom: 5rem;
  &:after {
    content: "";
    height: 2px;
    width: 60px;
    border-radius: 2.5px;
    background-color: #0066b2;
    position: absolute;
    bottom: -8px;
    margin-left: -30px;
    left: 50%;
  }
`;

export default IndexServiceSection;
