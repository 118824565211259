import React, { useState } from "react";
import styled from "styled-components";

const TechnologiesCard = ({ Icon, title, flip }) => {
  const [toggleView, setToggleView] = useState(false);

  const toggleDisplay = () => {
    setToggleView(!toggleView);
  };

  return (
    <Container
      toggleView={toggleView}
      onMouseEnter={toggleDisplay}
      onMouseLeave={toggleDisplay}
    >
      <TopSideContainer toggleView={toggleView}>
        <CardTop src={Icon}></CardTop>
        <CardTitle>{title}</CardTitle>
      </TopSideContainer>

      <NewFlip toggleView={toggleView}>
        <FlipTitle>{title}</FlipTitle>

        <FlipItemDivContainer>
          {flip.map((item) => (
            <ListItem>{item}</ListItem>
          ))}
        </FlipItemDivContainer>
      </NewFlip>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  margin: 5px;
  overflow: hidden;
  justify-content: center;
  padding: ${(props) => (props.toggleView ? "0px" : "20px")};
  height: 243px;
  background: #191919;
  flex-direction: column;
  width: 270px;
  border-radius: 4px;
`;

const CardTop = styled.img`
  align-self: center;
  color: red;
`;

const CardTitle = styled.p`
  color: #fff;
  align-self: center;
  font-family: 'Archivo';
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.4px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  padding-top: 20px;
`;
// const TechnologyIcon

const TopSideContainer = styled.div`
  display: ${(props) => (props.toggleView ? "none" : "flex")};
  flex-direction: column;
  width: 100%;
`;

const ListItem = styled.p`
  width: 48%;
  font-size: 15px;
  padding-left: 0px;
  font-family: 'Archivo';
  font-weight: 300;
  color: #191919;
  margin-bottom: auto;
  &::before {
    content: "•";
    color: #0066b2;
    margin-right: 5px;
    border-radius: 40%;
  }
`;
const FlipTitle = styled.p`
  width: 100%;
  font-family: 'Archivo';
  font-weight: 900;
  font-size: 15px;
  // letter-spacing: 0.4px;
  // line-height: 15px;
  text-align: center;
  text-transform: uppercase;
  padding-top: 3px;
  margin-top: 20px;
  padding-bottom: 10px;
`;

const FlipItemDivContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: auto;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
`;

const NewFlip = styled.div`
  display: ${(props) => (props.toggleView ? "flex" : "none")};
  flex-direction: column;
  min-height: 220px;
  background: #fff;
  width: 100%;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: center;
`;

export default TechnologiesCard;
