import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";


const OrganizationCard = ({icon }) => {
  return (
    <Container>
      <StyledImg src={icon.publicURL}>

      </StyledImg>
    </Container>
  );
};

const Container = styled(Link)`
  display: flex;
  background: #48494b;
margin: 10px;
  color: #fff;
  flex-direction: column;
  width: 18%;
  height: 100%;
  cursor: inherit;

  @media (max-width: 756px) {
    width: 70%;
    margin: 30px;
    flex-direction: column;
    align-items: center;
  }
`;








const StyledImg = styled.img.attrs()`

width: 100%;
height: 100%;
`;
export default OrganizationCard;
