import React from "react";
import styled from "styled-components";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { StaticQuery, graphql } from "gatsby";
import ItemContainer from "./ItemsContainer";

const Testimonials = () => {
  Carousel.apply = false;

  return (
    <StaticQuery
      query={graphql`
        query TestimonialSectionQuery {
          markdownRemark(
            frontmatter: { title: { eq: "TestimonialsSection" } }
          ) {
            id
            frontmatter {
              title
              testimonies {
                name
                background {
                  childImageSharp {
                    resize(width: 3000, jpegProgressive: true, quality: 100) {
                      src
                      tracedSVG
                      width
                      height
                      aspectRatio
                      originalName
                    }
                  }
                }
                testimony
              }
            }
          }
        }
      `}
      render={(data) => (
        <Container>
          <div style={{ marginBottom: "-41px" }}>
            <Carousel
              style={{ marginBottom: "50px" }}
              showArrows={true}
              showThumbs={true}
              showStatus={false}
              infiniteLoop={true}
              autoPlay={false}
            >
              {data.markdownRemark.frontmatter.testimonies.map((item) => (
                <ItemContainer
                  background={item.background.childImageSharp.resize.src}
                  name={item.name}
                  testimony={item.testimony}
                />
              ))}
            </Carousel>
          </div>
        </Container>
      )}
    />
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: static;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: 'Archivo';

  @media (max-width: 970px) {
    height: 500px;
  }
`;


export default Testimonials;
