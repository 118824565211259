import React from "react";
import styled from "styled-components";
import BlogComponent from "./BlogComponent";
import { Link } from "gatsby";
import { StaticQuery, graphql } from "gatsby";

const Blog = () => {
  return (
    <StaticQuery
      query={graphql`
        query BlogSectionQuery {
          markdownRemark(frontmatter: { title: { eq: "BlogSection" } }) {
            id
            frontmatter {
              title
              blogs {
                title
                text
                image {
                  publicURL
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <Container>
          <ContentContainer>
            <TopContainer>
              <TitleContainer>
                <Title>Our Blog</Title>
              </TitleContainer>

              <ViewAllContainer>
                <Link>
                  <ViewAllButton>View all</ViewAllButton>
                </Link>
              </ViewAllContainer>
            </TopContainer>

            <BlogContainer>
              {data.markdownRemark.frontmatter.blogs.map((item) => (
                <BlogComponent
                  title={item.title}
                  text={item.text}
                  date={item.date}
                  image={item.image.publicURL}
                ></BlogComponent>
              ))}
            </BlogContainer>
          </ContentContainer>
        </Container>
      )}
    />
  );
};

const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  width: 100%;
  font-family: 'Archivo';
  background: #242424;
  padding-bottom: 5rem;
  position: relative;
  @media (max-width: 950px) {
    padding-bottom: 4rem;
  }
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
`;

const BlogContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
  @media (max-width: 970px) {
    flex-direction: column;
    align-items: center;
    max-width: 980px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  margin-bottom: 20px;
`;
const Title = styled.p`
  font-family: 'Archivo';
  font-weight: 900;
  font-size: 32px;
  letter-spacing: 0.7px;
  line-height: 48px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  margin-bottom: 0;
  color: #fff;
  position: relative;
  &::after {
    content: "";
    height: 2px;
    width: 60px;
    border-radius: 2.5px;
    background-color: #0066b2;
    position: absolute;
    bottom: -8px;
    margin-left: -30px;
    left: 50%;
  }
`;

const ViewAllButton = styled.button`
  background: #0066b2;
  width: 150px;
  color: #fff;
  font-family: 'Archivo';
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.6px;
  height: 40px;
  border-radius: 3px;
  opacity: 0.75;
  border: 0px;
  transition: opacity ease-in 0.2s;
  &:hover {
    opacity: 1;
  }
`;

const ViewAllContainer = styled.p`
  margin-bottom: 100px;
  margin-top: 1.25rem;
  text-align: center;
`;

export default Blog;
