import React from "react";
import styled from "styled-components";
import BlueWreath from "../../img/Achievements/Wreath.png";

const AchievementWreath = ({ title, subtitle, topMargin }) => {
  return (
    <Container image={BlueWreath} topMargin={topMargin}>
      <Title>{title}</Title>
      <SubText>{subtitle}</SubText>
    </Container>
  );
};

const Container = styled.div`
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => (props.topMargin ? props.topMargin : "0px")};
  text-align: center;
  background-image: url(${(props) => (props.image ? props.image : "")});
  background-position: center;
  padding-top: 100px;
  padding-bottom: 150px;
  background-repeat: no-repeat;
  background-size: contain;
  font-family: 'Archivo';
  font-weight: 700;
  font-size: 48px;
  line-height: 34px;
  justify-content: center;

  @media (max-width: 1000px) {
    text-align: center;

    position: static;
    margin: 2rem auto;
    /* width: 100%; */
    width: 180px;
    height: 180px;
    padding: 20px;
    background-size: contain;
  }
  @media (max-width: 876px) {
    text-align: center;
    position: static;
    margin: 2rem auto;
    width: 100%;
    width: 180px;
    height: 180px;
    padding: 0;
    background-size: contain;
  }
  @media (max-width: 756px) {
    text-align: center;
    position: static;
    margin: 2rem auto;
    width: 100%;
    width: 180px;
    height: 180px;
    padding: 0;
    background-size: contain;
  }
`;

const Title = styled.p`
  align-self: center;
  font-weight: 900;
  font-size: 50px;
  margin-left: 30px;
  margin-right: 30px;
  &::after {
    content: " ";
    width: 60px;
    background: #0066b2;
    height: 2px;
    position: relative;
    margin-top: 18px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    display: block;
    @media (max-width: 1096px) {
      margin-top: 5px;
      margin-bottom: -10px;
    }
  }

  @media (max-width: 1096px) {
    align-self: center;
    font-weight: 900;
    font-size: 22px;
    padding-bottom: 1px;
  }

  @media (max-width: 995px) {
    align-self: center;
    font-weight: 900;
    font-size: 25px;
  }
  @media (max-width: 696px) {
    align-self: center;
    font-weight: 900;
    font-size: 20px;
  }
`;

const SubText = styled.p`
  font-family: 'Archivo';
  font-weight: 600;
  color: #fff;
  font-size: 20px;
  text-align: center;
  width: 160px;
  margin: 0 auto 2rem;
  text-transform: lowercase;
  @media (max-width: 976px) {
    margin: 0 auto;
    max-width: 50%;
    margin-top: 20px;
    line-height: 1.3;
    font-size: 15px;
    align-self: center;
    font-weight: 400;
    padding-bottom: 23px;
  }
`;

export default AchievementWreath;
