import React, { useState } from "react";

import styled from "styled-components";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import "swiper/swiper.scss";

import officeShot1 from "../../img/Officeshots/office 6webp.webp";
import officeShot3 from "../../img/Officeshots/office17.jpg";
// import officeShot4 from "../../img/Officeshots/office shot 1.jpg";
import officeShot5 from "../../img/Officeshots/office2.jpg";
import officeShot6 from "../../img/Officeshots/office3.jpg";
// import officeShot7 from "../../img/Officeshots/office4.jpg";
import officeShot8 from "../../img/Officeshots/office5.jpg";
import officeShot9 from "../../img/Officeshots/office7webp.webp";
import officeShot10 from "../../img/Officeshots/office01.jpg";

const ManagementCarousel = () => {
  const swiperRef = React.useRef(null);
  const [underlineFirst, setUnderlineFirst] = useState(true);
  const [underlineSecond, setUnderlineSecond] = useState(false);
  const [underlineThird, setUnderlineThird] = useState(false);

  const underLineTransition = (e) => {
    if (e.activeIndex <= 2 && e.activeIndex <= 7) {
      setUnderlineFirst(true);
      setUnderlineSecond(false);
      setUnderlineThird(false);
    } else if (e.activeIndex >= 3 && e.activeIndex <= 5) {
      setUnderlineFirst(false);
      setUnderlineSecond(true);
      setUnderlineThird(false);
    } else {
      setUnderlineFirst(false);
      setUnderlineSecond(false);
      setUnderlineThird(true);
    }
  };

  SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
  return (
    <Container>
      <TitleContainer>
        <Title>Office Shots</Title>
        <UnderlineContainer>
          <TitleUnderLine width={underlineFirst} />
          <TitleUnderLineSecond width={underlineSecond} background={"grey"} />
          <TitleUnderLineThird width={underlineThird} background={"grey"} />
        </UnderlineContainer>
      </TitleContainer>
      <SliderContainer>
        <Swiper
          spaceBetween={2}
          speed={400}
          slidesPerView={1}
          slidesPerGroup={1}
          onSlideChange={underLineTransition}
          ref={swiperRef}
          breakpoints={{
            970: {
              slidesPerView: 3,
              slidesPerGroup: 3,
              height: 100,
            },
          }}
        >
          <SwiperSlide>
            <Slick style={{ height: "100%" }} src={officeShot1} />
          </SwiperSlide>

          <SwiperSlide>
            <Slick style={{ height: "100%" }} s src={officeShot3} />
          </SwiperSlide>
          {/* <SwiperSlide>
            <Slick style={{ height: "100%" }} s src={officeShot4} />
          </SwiperSlide> */}
          <SwiperSlide>
            <Slick style={{ height: "100%" }} s src={officeShot5} />
          </SwiperSlide>
          <SwiperSlide>
            <Slick style={{ height: "100%" }} s src={officeShot6} />
          </SwiperSlide>

          <SwiperSlide>
            <Slick style={{ height: "100%" }} s src={officeShot8} />
          </SwiperSlide>
          {/* <SwiperSlide>
            <Slick style={{ height: "100%" }} src={officeShot7} />
          </SwiperSlide> */}
          <SwiperSlide>
            <Slick src={officeShot9} style={{ height: "100%" }} />
          </SwiperSlide>
          <SwiperSlide>
            <Slick src={officeShot10} style={{ height: "100%" }} />
          </SwiperSlide>
        </Swiper>
      </SliderContainer>
      <ButtonContainer>
        <StyledButtonLeft onClick={() => swiperRef.current.swiper.slidePrev()}>
          <CustomArrowLeft />
        </StyledButtonLeft>
        <Divider />
        <StyledButtonRight onClick={() => swiperRef.current.swiper.slideNext()}>
          <CustomArrowRight />
        </StyledButtonRight>
      </ButtonContainer>
    </Container>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  height: 100px;
  padding-top: 30px;
  padding-bottom: 15px;
  align-items: center;
  justify-content: center;
  background: #242424;
  @media (max-width: 970px) {
    order: 1;
    justify-content: space-between;
    padding-left: 70px;
    padding-right: 70px;
  }
`;

const SliderContainer = styled.div`
  @media (max-width: 970px) {
    order: 2;
  }
`;

const StyledButtonLeft = styled.i``;
const Divider = styled.i`
  height: 100%;
  display: flex;
  content: "";
  background: #353535;
  margin-left: 50px;
  margin-right: 50px;
  width: 0.2px;
`;

const StyledButtonRight = styled.i``;

const Container = styled.div`
  background: #242424;
  padding-top: 20px;
  padding-bottom: 70px;
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  width: 100%;
  height: 100px;
  background: #242424;
  max-width: 1200px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 20px;
  padding-top: 10px;
  flex-direction: column;
  @media (max-width: 970px) {
    margin-left: 50px;
  }
`;

const Title = styled.p`
  font-family: 'Archivo';
  font-weight: 900;
  letter-spacing: 0.7px;
  line-height: 48px;
  text-align: center;
  text-transform: uppercase;
  font-size: 32px;
  position: relative;
  color: #fff;
  margin-bottom: 10px;
  align-self: flex-start;
`;

const TitleUnderLine = styled.p`
  width: ${(props) => (props.width ? "60px" : "30px")};
  height: 1.5px;
  margin-right: 10px;
  transition: width 1s;
  background: ${(props) => (props.width ? "#0066b2" : "grey")};
`;
const TitleUnderLineSecond = styled.p`
  width: ${(props) => (props.width ? "60px" : "30px")};
  height: 1.5px;
  margin-right: 10px;
  transition: width 1s;
  background: ${(props) => (props.width ? "#0066b2" : "grey")};
`;

const TitleUnderLineThird = styled.p`
  width: ${(props) => (props.width ? "60px" : "30px")};
  height: 1.5px;
  margin-right: 10px;
  transition: width 1s;
  background: ${(props) => (props.width ? "#0066b2" : "grey")};
`;

const Slick = styled.img`
  margin: auto;
  width: 100%;
  height: 100%;
  -webkit-filter: grayscale(65%); /* Safari 6.0 - 9.0 */
  filter: grayscale(65%);
  transition: filter 0.1s;
  &:hover {
    filter: none;
  }

  @media (max-width: 970px) {
    width: 100% !important;
    height: 350px !important;
  }
`;

// const HalfSlick = styled.p`
//   height: ${(props) => (props.height ? props.height : "50%")};
//   color: #fff;
//   background: green;
//   padding-left: 5px;
//   font-weight: 10;
//   font-size: 15px;
//   & h2 {
//     font-weight: 40;
//     padding-bottom: 10px;
//     font-size: 25px;
//     text-transform: uppercase;
//   }
//   @media (max-width: 970px) {
//     height: 190px;
//   }
// `;

const CustomArrowLeft = styled(FaLongArrowAltLeft)`
  display: flex !important;
  height: 30px;
  width: 30px;
  z-index: 1000 !important;
  border-radius: 100%;
  transition: background 1s;
  color: #0066b2;
  opacity: 0.5;
  transition: opacity 0.2s;
  &:hover {
    opacity: 1;
  }
`;

const CustomArrowRight = styled(FaLongArrowAltRight)`
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  z-index: 1000 !important;
  border-radius: 100%;
  color: #0066b2;
  opacity: 0.5;
  &:hover {
    color: #0066b2;
    opacity: 1;
  }
`;
const UnderlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 20px;
`;

export default ManagementCarousel;
