import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const BlogComponent = ({ title, date, text, image }) => {
  return (
    <Container>
      <ContentContainer>
        <BlogLinks>
          <ImageContainer>
            <Image img={image} />
          </ImageContainer>
        </BlogLinks>
        <TextContainer>
          <DateContainer>
            <span>{date}</span>
          </DateContainer>
          <BlogLinks>
            <Title>{title}</Title>
          </BlogLinks>

          <SubTitle>{text}</SubTitle>
        </TextContainer>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  border: 0px;
  flex-direction: column;
  width: 528px;
  height: 100%;
  background: #191919;
  margin-right: 30px;
  margin-left: 30px;
  margin-bottom: 25px;
  justify-content: space-between;
  overflow: hidden;
  transition: transform ease-in 0.1s;
  &:hover {
    transform: scale(1.08);
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.5);
  }
  @media (max-width: 950px) {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 2rem;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: 'Archivo';
  /* transition: width ease-in .5s;
  &:hover{
    width: 550px;
  } */
`;

const ImageContainer = styled.div`
  position: relative;
  border-radius: 6px;
`;

const Image = styled.img.attrs((props) => ({
  src: props.img,
}))`
  width: 100%;
  height: 100%;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
`;

const TextContainer = styled.div`
  display: flex;
  background: #191919;
  color: #fff;
  flex-direction: column;
  border: 0px;
  height: 300px;
  width: 100%;
  padding: 2rem;
`;

const Title = styled.p`
  font-size: 1.25rem;
  line-height: 1.25;
  margin-bottom: 1rem;
  font-weight: 600;
  color: #fff;
  display: -ms-flex;
  display: -webkit-box;
  display: flex;
  -ms-align-items: center;
  -webkit-box-align: center;
  align-items: center;
  @media (max-width: 970px) {
    font-size: 1rem;
  }
`;

const SubTitle = styled.p`
  font-size: 1.1rem;
  margin-bottom: 4rem;
  font-weight: 350;
  align-items: center;
  @media (max-width: 970px) {
    font-size: 0.9rem;
  }
`;
const DateContainer = styled.p`
  font-size: 0.9rem;
  margin-bottom: 1rem;
  font-weight: 400;
  color: #bbb;
  align-items: center;
`;

const BlogLinks = styled(Link)`
  color: black;
`;

export default BlogComponent;
