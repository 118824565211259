import React from "react";

import styled from "styled-components";
import AchievementCard from "./AchievementCard";
import AchievementWreath from "./AchievementWreath";
import OrganizationCard from "./OrganizationCard";
import { StaticQuery, graphql } from "gatsby";

const Achievements = () => {
  return (
    <StaticQuery
      query={graphql`
        query AchievementSectionQuery {
          markdownRemark(frontmatter: { title: { eq: "AchievementSection" } }) {
            id
            frontmatter {
              title
              date
              AchievementCardata {
                text
                icon {
                  publicURL
                }
              }
              wreathData {
                title
                subtitle
                topMargin
              }
              CompanyData {
                icon {
                  publicURL
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <Container>
          <ContentContainer>
            <HeaderContainer>
              <HeaderTitle>Achievements</HeaderTitle>
            </HeaderContainer>

            <TopContainer>
              {data.markdownRemark.frontmatter.CompanyData.map((item) => (
                <OrganizationCard icon={item.icon} />
              ))}
            </TopContainer>
            <MiddleContainer>
              {data.markdownRemark.frontmatter.wreathData.map((item) => (
                <AchievementWreath
                  title={item.title}
                  subtitle={item.subtitle}
                  topMargin={item.topMargin}
                />
              ))}
            </MiddleContainer>
            <BottomContainer>
              {data.markdownRemark.frontmatter.AchievementCardata.map(
                (item) => (
                  <AchievementCard
                    icon={item.icon.publicURL}
                    text={item.text}
                  />
                )
              )}
            </BottomContainer>
          </ContentContainer>
        </Container>
      )}
    />
  );
};

const Container = styled.div`
  width: 100%;
  padding: 32px;
  display: flex;
  height: 100%;
  padding-bottom: 3rem;
  flex-direction: column;
  background: #191919;
`;
const ContentContainer = styled.div`
  max-width: 1200px;
  padding-bottom: 3rem;
  margin-left: auto;
  margin-right: auto;
`;

const MiddleContainer = styled.div`
  width: 100%;
  color: white;
  display: flex;
  height: 100%;
  padding-bottom: 3rem;
  font-family: 'Archivo';
  justify-content: center;
  @media (max-width: 756px) {
    margin-bottom: 0px;
    flex-direction: column;
    align-items: center;
  }
`;

const BottomContainer = styled.div`
  width: 100%;
  color: white;
  height: 100%;
  display: flex;
  justify-content: space-between;
  font-family: 'Archivo';
  padding-left: 60px;
  padding-right: 60px;
  flex-wrap: wrap;
  padding-right: 50px;

  @media (max-width: 950px) {
    flex-direction: column;
    padding-left: 1px;
    padding-right: 20px;
    align-items: center;
  }
`;

const TopContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  color: white;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 90px;
  @media (max-width: 756px) {
    flex-direction: column;
    align-items: center;
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 5rem;
  margin-bottom: 70px;
  text-transform: uppercase;
`;

const HeaderTitle = styled.p`
  text-align: center;
  color: #fff;
  font-weight: 900;
  font-size: 32px;
  font-family: 'Archivo';
  &::after {
    content: " ";
    width: 60px;
    background: #0066b2;
    height: 2px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -20px;
    margin-top: 12px;
    display: block;
    @media (max-width: 1096px) {
      margin-bottom: -60px;
    }
  }
`;

export default Achievements;
