import React from "react";
import styled from "styled-components";
import MapCard from "./MapCard";

const data = [
  { title: " Sales & Partnerships:", email: "hello@scrumanac.com" },
  { title: " HR Department:", email: "hello@scrumanac.com" },
  { title: " Office Administration:", email: "hello@scrumanac.com" },
];

const ContactUs = () => {
  return (
    <MainContainer>
      <ContactUsWrapper>
        <ContentContainer>
          <ContentUsHeader>
            <ContactUsLeft>
              CONTACT US
              <HeaderUnderline />
            </ContactUsLeft>
            <ContactHeaderRight>
              {data.map((item) => (
                <ContactHeaderRightItem>
                  <ContactHeaderContent>{item.title}</ContactHeaderContent>
                  <ContactHeaderEmail email href={"mailto:" + item.email}>
                    {item.email}
                  </ContactHeaderEmail>
                </ContactHeaderRightItem>
              ))}
            </ContactHeaderRight>
          </ContentUsHeader>
        </ContentContainer>
        <MapCard></MapCard>
      </ContactUsWrapper>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;
  padding-bottom: 5rem;
  justify-content: center;
  background: #191919;
`;

const ContentContainer = styled.div`
  display: flex;
  width: 1200px;
  height: 100%;
  align-self: center;
  font-family: 'Archivo';
  transition: background 2s;
  @media (max-width: 970px) {
    width: 100%;
  }
`;
const ContentUsHeader = styled.div`
  display: flex;
  padding-top: 60px;
  padding-bottom: 20px;
  flex-direction: row;
  width: 100%;

  font-family: 'Archivo';
  @media (max-width: 950px) {
    flex-direction: column;
    text-align: center;
  }
`;
const ContactUsLeft = styled.p`
  align-self: left;
  padding: 20px;
  color: #fff;
  font-family: 'Archivo';
  width: 100%;
  font-size: 32px;
  font-weight: 800;
  transition: color 1s;

  transition: opacity ease-in 0.7s;

  @media (max-width: 970px) {
    padding: 60px;
    color: #fff;
    text-align: -webkit-center;
  }
`;

const ContactHeaderRight = styled.div`
  display: flex;
  flex-direction: row;
  align-self: right;
  padding: 20px;
  flex-basis: 100%;
  font-family: 'Archivo';
  color: #0066b2;
  @media (max-width: 950px) {
    flex-direction: column;
    text-align: center;
    padding: 20px;
  }
`;

const ContactHeaderRightItem = styled.p`
  display: flex;
  width: 200px;
  margin-left: 5px;
  margin-right: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: 'Archivo';
  flex-direction: column;
  @media (max-width: 970px) {
    align-items: center;
    width: 100%;
  }
`;

const ContactHeaderContent = styled.p`
  line-height: 20px;
  color: #fff;
  width: 100px;
  font-weight: 400;
  font-size: 19px;
  margin-bottom: 30px;
  padding-right: 15px;
  transition: opacity ease-in 0.7s;
`;

const ContactHeaderEmail = styled.a`
  line-height: 0.2px;
  color: #fff;
  font-family: 'Archivo';
  width: 40%;
  font-weight: 800;
  font-size: 15px;
  margin-bottom: 30px;
  padding-right: 15px;
  transition: opacity ease-in 0.7s;
  &:hover {
    color: #0066b2;
  }

  @media (max-width: 970px) {
    width: 100%;
    color: #0066b2;
  }
`;

const ContactUsWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  @media (max-width: 970px) {
    max-width: 100%;
    min-width: -moz-available;
  }
`;

const HeaderUnderline = styled.p`
  height: 2px;
  width: 60px;
  max-width: 60px;
  background: #0066b2;
  margin-top: 8px;
  margin-left: 10px;
  @media (max-width: 970px) {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }
`;

export default ContactUs;
