import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from "styled-components";

import ServiceSection from "../components/IndexServiceCard/IndexServiceSection";
import TechnologySection from "../components/Technologies/Technologies";
import Modal from "../components/Modal/Modal";
import Blog from "../components/Blog/Blog";
import Testimonials from "../components/Testimonials/Testimonials";
import ContactUs from "../components/ContactUs/ContactUs";
import Projects from "../components/ProJects/Projects";
import Achievements from "../components/Achievements/Achievements";
import OfficeShots from "../components/OfficeShots/OfficeShots";
import { FaLongArrowAltDown } from "react-icons/fa";

import BgImg from "../img/Topsection/bgtest.jpg";


export const IndexPageTemplate = () => (
  <Container>
    <TopSectionContainer>
      <InnerContainer>
        <StyledText>
          <TopSpan>Web,</TopSpan>
          <br />
          <NetSpan>Cloud</NetSpan>
          <AndSpan>and</AndSpan>
          <br />
          <MobileSpan>Mobile</MobileSpan>
          <br />
          <ApplicationSpan>application</ApplicationSpan>
          <br />
          <DevelopmentSpan>development</DevelopmentSpan>
        </StyledText>
        <Modal />
        <ScrollContainer>
          <Link to="/#services">
            <FaLongArrowAltDown />
          </Link>
        </ScrollContainer>
      </InnerContainer>
    </TopSectionContainer>
    <section id="services">
      <ServiceSection />
    </section>
    <Achievements />
    <Projects />
    {/* <ClientSection /> */}
    <Testimonials />
    <TechnologySection />
    <Blog />
    <OfficeShots />
    {/* <ManagementSection /> */}
    <section id="contactus">
      <ContactUs />
    </section>
  </Container>
);

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};


const InnerContainer = styled.div`
  max-width: 1200px;
  padding-top: 230px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  min-height: calc(100vh - 90px);
  justify-content: center;
  position: relative;
  @media (max-width: 970px) {
    padding-left: 80px;
  }
  @media (max-width: 513px) {
    padding-left: 40px;
  }
  @media (max-width: 400px) {
    padding-left: 0px;
  }
`;

const TopSectionContainer = styled.div`
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  background-color: #191919;
  position: relative;
  background-image: url(${BgImg});
  background-position: center;
  /* padding-bottom: 100px; */
`;
const StyledText = styled.p`
  color: #fff;
  padding-left: 20px;
  text-transform: uppercase;
  font-family: 'Archivo';
  line-height: 2.9;
  text-transform: uppercase;

  font-weight: 1000;
`;
const TopSpan = styled.span`
  font-size: 100px;
  letter-spacing: -2px;
  line-height: 0.75;
  @media (max-width: 970px) {
    font-size: 40px;
  }
`;
const NetSpan = styled.span`
  font-size: 100px;
  letter-spacing: -2px;
  line-height: 0.75;
  position: relative;
  left: -5px;
  @media (max-width: 970px) {
    margin-left: 2px;
    font-size: 40px;
  }
`;
const AndSpan = styled.span`
  font-size: 32px;
  position: relative;

  @media (max-width: 970px) {
    font-size: 20px;
  }
`;
const MobileSpan = styled.span`
  font-size: 100px;
  letter-spacing: -2px;
  line-height: 0.65;
  @media (max-width: 970px) {
    font-size: 40px;
  }
`;
const ApplicationSpan = styled.span`
  font-size: 40px;
  line-height: 1;
`;
const DevelopmentSpan = styled.span`
  font-size: 32px;
  line-height: 1;
`;

const ScrollContainer = styled.div`
  margin-top: 80px;
  padding-bottom: 60px;
  svg {
    display: flex;
    margin-right: auto;
    margin-left: auto;
    width: 50px;
    height: 50px;
    color: #fff;
    padding: 10px;
    background: #0066b2;
    border-radius: 50%;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;

const Container = styled.div`
  scroll-behavior: smooth;
`;
