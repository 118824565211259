import React from "react";
import styled from "styled-components";

const ProjectDetails = ({ details }) => {
  return (
    <DetailsContainer>
      <ItemContainer>
        <ProjectDetaisItemName>Location</ProjectDetaisItemName>
        <ProjectDetaisItem>{details[0].location}</ProjectDetaisItem>
      </ItemContainer>
      <ItemContainer>
        <ProjectDetaisItemName>Platform</ProjectDetaisItemName>
        <ProjectDetaisItem>{details[0].platforms}</ProjectDetaisItem>
      </ItemContainer>
      <ItemContainer>
        <ProjectDetaisItemName>Technologies</ProjectDetaisItemName>
        <ProjectDetaisItem>{details[0].technologies}</ProjectDetaisItem>
      </ItemContainer>
      <ItemContainer>
        <ProjectDetaisItemName>Customer Quote</ProjectDetaisItemName>
        <ProjectDetaisItem>{details[0].customerQuote}</ProjectDetaisItem>
      </ItemContainer>
      <ItemContainer>
        <ProjectDetaisItemName>Team</ProjectDetaisItemName>
        <ProjectDetaisItem>{details[0].team}</ProjectDetaisItem>
      </ItemContainer>
    </DetailsContainer>
  );
};

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: 'Archivo' , Futura;

  @media (max-width: 600px) {
    flex-direction: column;
    width: 100%;cccccccc
  }
`;
const ProjectDetaisItem = styled.p`
  width: 70%;
  font-weight: 400;
  font-size: 15px;
  @media (max-width: 970px) {
    font-weight: 500;
    font-size: 11.5px;
  }
`;
const ProjectDetaisItemName = styled.p`
  width: 30%;
  font-weight: 750;
  font-size: 15px;
  @media (max-width: 970px) {
    font-weight: 750;
    font-size: 12px;
  }
`;

const ItemContainer = styled.div`
  display: flex;
  font-family: "Archivo";
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
  @media (max-width: 970px) {
    margin-bottom: 3px;
    flex-direction: column;
  }
`;

export default ProjectDetails;
