import React from "react";
import styled from "styled-components";
import { StaticQuery, graphql } from "gatsby";
import TechnologyCard from "./TechnologiesCard";

const Technologies = () => {
  return (
    <StaticQuery
      query={graphql`
        query IndexPageTemplate {
          markdownRemark(
            frontmatter: { title: { eq: "TechnologiesSection" } }
          ) {
            id
            frontmatter {
              title
              templateKey
              poster {
                title
                icon {
                  publicURL
                }
                flip
              }
            }
          }
        }
      `}
      render={(data) => (
        <Container>
          <ContentContainer>
            <Title>Technologies</Title>
            <TechnologyContainer>
              {data.markdownRemark.frontmatter.poster &&
                data.markdownRemark.frontmatter.poster.map &&
                data.markdownRemark.frontmatter.poster.map((item) => (
                  <TechnologyCard
                    Icon={item.icon.publicURL}
                    title={item.title}
                    flip={item.flip}
                  ></TechnologyCard>
                ))}
            </TechnologyContainer>
          </ContentContainer>
        </Container>
      )}
    />
  );
};

const Container = styled.div`
  background: #242424;
  display: flex;
  height: 100%;
  flex-wrap: wrap;
  font-family: 'Archivo';
  background: #242424;
  position: relative;
  z-index: 3;
  max-width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const ContentContainer = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1200px;
  max-width: 1240px;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const Title = styled.p`
  font-family: 'Archivo';
  font-weight: 900;
  letter-spacing: 0.7px;
  line-height: 48px;
  padding-top: 3rem;
  margin-bottom: 0;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-size: 32px;
  position: relative;
  &:after {
    content: " ";
    height: 2px;
    width: 70px;
    border-radius: 2.5px;
    background-color: #0066b2;
    position: absolute;
    bottom: -8px;
    margin-left: -30px;
    left: 50%;
  }
`;

const TechnologyContainer = styled.div`
  padding-top: 5rem;
  padding-bottom: 5rem;
  font-family: 'Archivo';
  justify-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

export default Technologies;
