import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const ServiceCard = ({ icon, title, introduction,FlipTitle }) => {
  const [visible, setVisible] = useState(true);

  const toggleContent = () => {
    setVisible(!visible);
  };

  return (
    <Item onMouseEnter={toggleContent} onMouseLeave={toggleContent}>
      {visible ? (
        <ItemContentContainer hover={false}>
          <img src={icon} alt='service icon'/>
          <ServiceTitle>{title}</ServiceTitle>
        </ItemContentContainer>
      ) : (
        <ServiceIntroduction hover={true}>
          <Title>{FlipTitle}</Title>
          {introduction}
          <ReadMore>Read More {'>'}</ReadMore>
        </ServiceIntroduction>
      )}
    </Item>
  );
};

const Item = styled.div`
     position: relative;
    width: 31%;
    margin-bottom: 20px;
   margin-left: 20px;
  height: 220px;

  @media (max-width: 819px) {
    width: 83%;
    align-self: center;
    margin-left: 0px;
  }
`;

const ItemContentContainer = styled.div`
  background: #${(props) => (props.hover ? "000" : "fff")};
  color: #${(props) => (props.hover ? "fff" : "000")};
  border-radius: 6px;
  box-shadow: 0 12px 24px 0 rgb(0 0 0 / 10%);
  padding: 1rem;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const ServiceTitle = styled.p`
  color: #191919;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 1;
  margin: 0;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
`;

const ServiceIntroduction = styled.div`
font-size: 13px;
  background: #${(props) => (props.hover ? "000" : "fff")};
  color: #${(props) => (props.hover ? "fff" : "000")};
  border-radius: 6px;
  box-shadow: 0 12px 24px 0 rgb(0 0 0 / 10%);
  padding: 1rem;
  padding-top: 10px;
  height: 100%;
  text-align:center;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;
const ReadMore = styled(Link)`
    color: #0066b2;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 1.6px;
    line-height: 32px;
    text-transform: uppercase;
    position: relative;
`;

const Title = styled.p`
font-size: 1rem;
    font-weight: 600;
    color: #fff;
    letter-spacing: .4px;
    line-height: 1;
    padding-top: 5px;
    padding-bottom: 5px;
    margin: 0;
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
    text-transform: uppercase;
    @media(max-width:900px){
      font-size: .8rem;
    }
`;

export default ServiceCard;
