import React from "react";

import styled from "styled-components";

const AchievementCard = ({ icon, text }) => {
  return (
    <CardContainer>
      <IconContainer src={icon} />
      <TextContainer>{text}</TextContainer>
    </CardContainer>
  );
};

const CardContainer = styled.div`
  display: flex;
  height: 120px;
  flex-direction: row;
  align-items: center;
  width: 48%;
  font-family: 'Archivo';
  margin-top: 20px;
  margin-bottom: 20px;
  /* padding:40px; */
  justify-content: space-between;
  flex-wrap: wrap;
  color: #fff;
  @media (max-width: 950px) {
    flex-direction: row;
    width: 100%;
    margin-top: 10px;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 10px;
  }
`;

const IconContainer = styled.img.attrs()`
  position: static;

  @media (max-width: 970px) {
    width: 50px;
    margin-right: 40px;
  }
`;
const TextContainer = styled.p`
  width: 80%;
  color: #fff;
  font-family: 'Archivo';
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  padding-right: 50px;
  margin: 0;
  @media (max-width: 950px) {
    padding-left: 20x;
    padding-right: 5px;
    padding-bottom: 5px;
    font-weight: 500;
    font-size: 1rem;
    line-height: 24px;
  }
`;

export default AchievementCard;
