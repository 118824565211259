import React, { useState, useRef } from "react";
import styled from "styled-components";
import SwiperCore, { Controller, Navigation, Pagination } from "swiper";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import { StaticQuery, graphql } from "gatsby";

import { Swiper, SwiperSlide } from "swiper/react";
// import { Link } from "gatsby";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";
// import Button from "./Button";
import ProjectDetails from "./ProjectDetails";
const Projects = () => {
  SwiperCore.use([Controller, Navigation, Pagination]);
  const [firstSwiper, setFirstSwiper] = useState(null);
  const [secondSwiper, setSecondSwiper] = useState(null);
  const swiperRef = useRef(null);

  return (
    <StaticQuery
      query={graphql`
        query ProjectsSectionQuery {
          markdownRemark(frontmatter: { title: { eq: "ProjectsSection" } }) {
            id
            frontmatter {
              title
              projects {
                image {
                  childImageSharp {
                    resize(width: 3500, jpegProgressive: true, quality: 100) {
                      src
                      tracedSVG
                      width
                      height
                      aspectRatio
                      originalName
                    }
                  }
                }
                title
                subtitle
                description
                details {
                  location
                  platforms
                  technologies
                  customerQuote
                  team
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <Container>
          <CarouselContainer>
            <SliderInnerImage>
              <Swiper
                loop={true}
                spaceBetween={0}
                slidesPerView={1}
                onSwiper={setFirstSwiper}
                controller={{ control: secondSwiper }}
              >
                {data.markdownRemark.frontmatter.projects.map((item) => (
                  <SwiperSlide>
                    {" "}
                    <StyledImg
                      src={item.image.childImageSharp.resize.src}
                    />{" "}
                  </SwiperSlide>
                ))}
              </Swiper>
            </SliderInnerImage>

            <HeaderContainer>
              <ProjectHeaderTitle>PROJECTS</ProjectHeaderTitle>

              <TitleUnderline></TitleUnderline>
              <ViewAllButton>VIEW ALL</ViewAllButton>
            </HeaderContainer>
            <SliderInner>
              <SlideHeaderContainer>
                <ProjectHeaderTitle>PROJECTS</ProjectHeaderTitle>
                <TitleUnderline></TitleUnderline>
                <ViewAllButton>VIEW ALL</ViewAllButton>
              </SlideHeaderContainer>
              <Swiper
                loop={true}
                style={{ height: "800px" }}
                breakpoints={{
                  970: {
                    pagination: { clickable: true },
                  },
                }}
                ref={swiperRef}
                slidesPerView={1}
                onSwiper={setSecondSwiper}
                controller={{ control: firstSwiper }}
                pagination={{ clickable: true }}
              >
                {data.markdownRemark.frontmatter.projects.map((item) => (
                  <SwiperSlide>
                    <TextDiv>
                      <ProjectTitle>{item.title}</ProjectTitle>
                      <ProjectSubTitle>{item.subtitle}</ProjectSubTitle>
                      <ProjectDescription>
                        {item.description}
                      </ProjectDescription>
                      <ProjectDetailsContainer>
                        <ProjectDetails details={item.details} />
                      </ProjectDetailsContainer>
                      <ProjectsViewMore
                      // borderRadius={"6px"}
                      // color={"#fff"}
                      // text={"READ MORE"}
                      // width={"300px"}
                      // background={"#0066b2"}
                      // height={"60px"}
                      // marginTop={"40px"}
                      >
                        Read More
                      </ProjectsViewMore>
                    </TextDiv>
                  </SwiperSlide>
                ))}
              </Swiper>
              <NavButtonContainer>
                <StyledLeftArrow
                  onClick={() => swiperRef.current.swiper.slidePrev()}
                  size={30}
                />

                <StyledRightArrow
                  onClick={() => swiperRef.current.swiper.slideNext()}
                  size={30}
                />
              </NavButtonContainer>
            </SliderInner>
          </CarouselContainer>
        </Container>
      )}
    />
  );
};

const Container = styled.div`
  width: 100%;
  height: 900px;
  font-family: 'Archivo';
  @media (max-width: 970px) {
    height: 950px;
  }
`;

const CarouselContainer = styled.div`
  display: flex;
  height: 1100px;
  margin-bottom: 200px;
  @media (max-width: 970px) {
    height: 950px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1px;
    margin-bottom: 0px;
  }
`;

const TextDiv = styled.div`
  max-width: 1100px;
  padding-left: 40px;
  padding-right: 20px;
  display: flex;
  max-height: 950px;
  flex-direction: column;
  padding-top: 10px;
  text-align: left;
  margin-top: 20px;

  min-height: 524px;
  @media (max-width: 970px) {
    width: 100%;
    padding-left: 50px;
    padding-right: 10px;
    padding-top: 0px;
    max-width: 600px;
    min-height: 530px;
  }

  @media (max-width: 500px) {
    min-height: 650px;
  }
`;

const ProjectsViewMore = styled.button`
  background: #0066b2;
  height: 50px;
  width: 150px;
  border-radius: 10px;
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: auto;
`;

const ProjectHeaderTitle = styled.p`
  font-weight: 900;
  font-size: 32px;
  margin-bottom: 4px;
  padding-top: 5rem;
  font-family: 'Archivo';
  @media (max-width: 970px) {
    padding-top: 3rem;
  }
`;
const TitleUnderline = styled.p`
  width: 50px;
  background: #0066b2;
  margin-bottom: 7px;
  height: 3px;
`;

const ViewAllButton = styled.button`
  background: #0066b2;
  width: 150px;
  color: #fff;
  font-family: 'Archivo';
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.6px;
  height: 40px;
  border-radius: 3px;
  opacity: 0.75;
  border: 0px;
  transition: opacity ease-in 0.2s;
  &:hover {
    opacity: 1;
  }
  @media (max-width: 1000px) {
    margin-bottom: -50px;
  }
`;

const ProjectTitle = styled.p`
  width: 100%;
  font-weight: 850;
  font-size: 27px;
  text-transform: uppercase;
  @media (max-width: 970px) {
    font-size: 16px;
    font-weight: 950;
    max-width: 250px;
  }
`;
const ProjectSubTitle = styled.p`
  width: 100%;
  font-weight: 700;
  font-size: 15px;
  text-transform: uppercase;
  margin-bottom: 20px;
  @media (max-width: 970px) {
    font-weight: 800;
    font-size: 12px;
    margin-bottom: 17px;
    margin-top: 5px;
    max-width: 250px;
  }
`;
const ProjectDescription = styled.p`
  width: 100%;
  font-weight: 300;
  max-width: 800px;
  font-size: 17px;
  margin-bottom: 30px;
  @media (max-width: 1300px) {
    max-width: 750px;
  }
  @media (max-width: 970px) {
    width: 100%;
    font-weight: 400;
    max-width: 600px;
    font-size: 13px;
    margin-bottom: 10px;
  }
  @media (max-width: 540px) {
    max-width: 300px;
  }
`;

const ProjectDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1300px) {
    max-width: 550px;
  }
`;

const NavButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: auto;
  margin-left: 40px;
  width: 26%;
  @media (max-width: 1070px) {
    margin-top: 0px;
    margin-bottom: 15px;
    margin-left: 10px;
    width: 50%;
  }
  @media (max-width: 970px) {
    display: none;
  }
`;
const StyledLeftArrow = styled(FaLongArrowAltLeft)`
  /* position: absolute; */
  top: 0.875rem;
  padding: 10px;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background: #0066b2;
  color: #fff;
  -moz-opacity: 0.75;
  -khtml-opacity: 0.75;
  -webkit-opacity: 0.75;
  opacity: 0.75;
  will-change: background, opacity;
  -webkit-transition: background 0.3s, opacity 0.3s;
  transition: background 0.3s, opacity 0.3s;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
`;
const StyledRightArrow = styled(FaLongArrowAltRight)`
  width: 3rem;
  height: 3rem;
  padding: 10px;
  border-radius: 50%;
  background: #0066b2;
  color: #fff;
  -moz-opacity: 0.75;
  -khtml-opacity: 0.75;
  -webkit-opacity: 0.75;
  opacity: 0.75;
  will-change: background, opacity;
  -webkit-transition: background 0.3s, opacity 0.3s;
  transition: background 0.3s, opacity 0.3s;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
`;
const StyledImg = styled.img`
  height: 900px;
  width: 99%;
  object-fit: cover;
  @media (max-width: 970px) {
    height: 100%;
    width: 100%;
  }
`;

const SliderInnerImage = styled.div`
  width: 40%;
  height: 900px;
  @media (max-width: 970px) {
    max-width: 100%;
    height: 100%;
  }
`;

const SliderInner = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  height: 844px;
  @media (max-width: 970px) {
    height: 100%;
    width: 100%;
  }
`;

const HeaderContainer = styled.div`
  display: none;
  margin-bottom: "230px";
  @media (max-width: 970px) {
    display: flex;
    align-items: center;
    flex-direction: column;
    order: -1;
    width: 100%;
  }
`;
const SlideHeaderContainer = styled.div`
  padding-left: 40px;
  margin-bottom: "30px";
  @media (max-width: 970px) {
    display: none;
  }
`;

export default Projects;
